import React, {useContext, useEffect} from "react";
import Account from "@components/Account/pages/index";
import {navigate} from "gatsby-plugin-react-intl";
import AuthContext from '@src/auth/context'
import {observer} from "mobx-react-lite";

const Index = () => {

    const { auth } = useContext(AuthContext);

    if(!auth.isAuth && !auth.isLoading){
        navigate("/users/login");
    }

    return (
        <Account />
    )
}

export default observer(Index);


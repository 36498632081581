import React from 'react'
import clsx from "clsx"
import { makeComponentStyles } from "@components/mixins.js"
import edit from '@static/images/edit.svg';

const styles = ({ palette }) => ({
  root: {
    width: "150px",
    height: "150px"
  },
  cover: {
    objectFit: "cover",
    borderRadius: "50%"
  },
  label: {
    cursor: "pointer",
    position: 'relative',
    width: "150px",
    height: "150px",
    display: "block"
  },
  label__input: {
    display: "none"
  },
  label__icon: {
    position: "absolute",
    zIndex: 1,
    right: -14,
    top: 20,
    background: palette.primary.secondary,
    padding: "10px",
    borderRadius: ".5rem",
  },
  icon__image: {
    height: "20px",
    display: "block"
  }
})

function ImageCover({ className, component, ...rest }){
  const { cover } = makeComponentStyles(styles);
  const props = {
    ...rest,
    className: clsx(className, cover)
  }
  const Component = component;
  return (
    <Component {...props}/>
  )
}

function ImageLabel({ labelClassName = "", onChange, ...rest }){
  const {
    label,
    label__input,
    label__icon,
    icon__image
  } = makeComponentStyles(styles);
  labelClassName = clsx(label, labelClassName);

  function handleInputChange(event){
    onChange(event);
  }

  return (
    <label className={labelClassName}>
      <ImageCover {...rest}  />
      <input type="file" className={label__input} onChange={handleInputChange} />
      <div className={label__icon}><img src={edit} alt="edit-icon" className={icon__image} /></div>
    </label>
  )
}

const types = {
  cover: ImageCover,
  label: ImageLabel
}

function Image({ type = "cover", component, alt = "image", className = "", ...rest }){
  const { root } = makeComponentStyles(styles);
  const ImageTemplate = types[type];
  const props = {
    component,
    alt,
    className: clsx(root, className),
    ...rest
  }
  return (
    <ImageTemplate {...props} />
  )
}

export default Image;

import React, { useContext } from "react"
import Image from "@components/Account/Image.jsx";
import { makeComponentStyles } from "@components/mixins.js"
import { Typography } from "@material-ui/core"
import AccountContext from '../context.js';
import { navigate } from "gatsby-plugin-react-intl"
import ArrowRight from "@static/images/ArrowRight.svg"
import ButtonLink from "@components/Buttons/ButtonLink.jsx"
import AuthContext from '@src/auth/context'
import {useIntl} from "gatsby-plugin-react-intl";
import Avatar from "@material-ui/core/Avatar"

const HeaderStyles = ({ breakpoints }) => ({
  header: {
    marginBottom: "25px",
    width: "100%",
    display: "flex",
    alignItems: "center"
  },
  header__user_avatar: {
    width: "70px !important",
    height: "70px !important"
  },
  header__user_name: {
    marginLeft: "15px"
  }
})

function Header({ user: {avatar, name, surname} }){
  const {
    header,
    header__user_name,
    header__user_avatar
  } = makeComponentStyles(HeaderStyles);
  return (
    <div className={header}>
      <Image component={
        ({ className }) => <Avatar alt={name} src={avatar ?? ' '} className={className} />
      } className={header__user_avatar} />
      <div className={header__user_name}>
        <Typography>{name}</Typography>
        <Typography>{surname}</Typography>
      </div>
    </div>
  )
}

const ButtonStyles = ({ palette }) => ({
  button__container: {
    cursor: "pointer",
    padding: "15px 24px",
    borderBottom: "solid 1px " + palette.text.muted,
    transition: "background .25s ease",
    "&:hover": {
      background: "rgba(0,0,0, .05)"
    }
  },
  button: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    fontSize: "18px !important"
  },
})

function Button({ children, to, onClick }) {
  const { button, button__container } = makeComponentStyles(ButtonStyles)
  const handleRedirect = () => navigate(to)
  const handleClick = () => {
    if(to) return handleRedirect();
    else return onClick()
  }
  return (
    <div onClick={handleClick} className={button__container}>
      <ButtonLink className={button}>
        {children} <img src={ArrowRight} alt="arrow-right" />
      </ButtonLink>
    </div>
  )
}

const MailStyles = ({ palette, breakpoints }) => ({
  link__container: {
    margin: "0 -16px",
    [breakpoints.down('sm')]: {
      margin: "0 -24px"
    },
    borderTop: "solid 1px " + palette.text.muted
  },
  btn_logout: {
    marginTop: "35px",
    color: palette.error.main,
    fontSize: "18px !important"
  }
})

function Mobile(){
  const { link__container, btn_logout } = makeComponentStyles(MailStyles)
  const { user, setModalState } = useContext(AccountContext);
  const { auth } = useContext(AuthContext)
  const intl = useIntl();
  const logout = () => {
    auth.logout();
    navigate("/")
  }

  return (
    <>
      <Header user={user}/>
      <div className={link__container}>
        <Button to={"/users/account/settings"}>{intl.formatMessage({id: "settings"})}</Button>
        <Button to={"/users/account/appointment"}>{intl.formatMessage({id: "make_appointment"})}</Button>
        <Button onClick={() => setModalState(true)}>{intl.formatMessage({id: "suggest_event"})}</Button>
      </div>
      <ButtonLink onClick={logout} className={btn_logout}>{intl.formatMessage({id: "logout"})}</ButtonLink>
    </>
  )
}

export default Mobile;

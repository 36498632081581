import React, {useContext, useEffect, useState} from "react"
import Layout from "@components/Layout/Layout.jsx"
import MainWrap from "@components/Wrap/MainWrap.jsx"
import { Grid, Typography, useMediaQuery } from "@material-ui/core"
import Container from "@components/Container/Container.jsx"
import Desktop from './components/Desktop.jsx';
import Mobile from './components/Mobile.jsx';
import AccountContext from "./context.js";
import Modal from "@components/Dialog/Modal.jsx"
import {Form, FormControl, FormFeedback, FormGroup, Label} from "@components/Forms"
import required from "@rules/required.js"
import Button from "@components/Buttons/Button.jsx"
import {makeComponentStyles} from "@components/mixins.js"
import Alert from "@components/Dialog/Alert";
import AuthContext from '@src/auth/context';
import {observer} from "mobx-react-lite";
import { SUGGEST_EVENT } from '@api/modules/requests';
import moreThanDate from '@rules/moreThanDate.js';
import rule from '@validator/rule';
import {encodeTimestamp} from "@src/heplers";
import {useIntl} from "gatsby-plugin-react-intl";
import { GET_SERVICE_ORDERS } from '@api/modules/requests';
import { decodeTimestamp } from "@src/heplers.js";


const initBody = {
    title: "",
    started_at: "",
    finished_at: "",
    message: ""
}

const ModalStyles = () => ({
    form: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        paddingBottom: "15px"
    },
    btn_submit: {
        marginTop: 25
    },
    input_message: {
        resize: "none",
        height: 250
    }
})

const EventModal = ({state, setState}) => {
    const {
        form,
        btn_submit,
        input_message
    } = makeComponentStyles(ModalStyles)
    const [body, setBody] = useState(initBody)
    const intl = useIntl();
    const [alertState, setAlertState] = useState(false);
    const observeBody = (key, event) => {
        const getTargetValue = () => {
            switch (event.target.type){
                case "date": return encodeTimestamp(event.target.value);
                default: return event.target.value
            }
        }
        setBody(body => ({
            ...body,
            [key]: getTargetValue()
        }))
    }

    useEffect(() => {
        if (!alertState) setState(false)
    }, [alertState])

    const handleSubmit = async (event) => {
        event.preventDefault();
        try{
            const { data } = await SUGGEST_EVENT(body);
            setAlertState(true);
        } catch(err){}
    }

    return (
        <>
            <Modal
                title={intl.formatMessage({id: "suggest_event"})}
                state={state}
                setState={setState}
            >
                <Form validate onSubmit={handleSubmit} className={form}>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <FormGroup name={"title-input"}>
                                <Label>{intl.formatMessage({id: "event_name"})}</Label>
                                <FormControl
                                    onChange={observeBody.bind(null, "title")}
                                    validations={[required]}
                                />
                                <FormFeedback/>
                            </FormGroup>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <FormGroup name={'started-input'}>
                                <Label>{intl.formatMessage({id: "event_start_date"})}</Label>
                                <FormControl
                                    type={"date"}
                                    onChange={observeBody.bind(null, 'started_at')}
                                    validations={[
                                        required,
                                        rule(moreThanDate, { value: 'now', message: "more.today"})
                                    ]}
                                />
                                <FormFeedback/>
                            </FormGroup>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <FormGroup name={'finished-input'}>
                                <Label>{intl.formatMessage({id: "event_finish_date"})}</Label>
                                <FormControl
                                    type={"date"}
                                    onChange={observeBody.bind(null, 'finished_at')}
                                    validations={[
                                        required,
                                        rule(moreThanDate, { value: body.started_at, message: "more.start"})
                                    ]}
                                />
                                <FormFeedback/>
                            </FormGroup>
                        </Grid>
                        <Grid item xs={12}>
                            <FormGroup name={'message-input'}>
                                <Label>{intl.formatMessage({id: "message"})}</Label>
                                <FormControl
                                    type={"textarea"}
                                    onChange={observeBody.bind(null, 'message')}
                                    validations={[required]}
                                    className={input_message}
                                />
                                <FormFeedback/>
                            </FormGroup>
                        </Grid>
                    </Grid>
                    <Button type="submit" color={"primary"} className={btn_submit}>{intl.formatMessage({id: "send"})}</Button>
                </Form>
            </Modal>
            <Alert
                state={alertState}
                setState={setAlertState}
            >
                {intl.formatMessage({id: "event_success_message"})}
            </Alert>
        </>
    )
}

const appointmentsSerializer = (array) =>
  array.map(({ body }) => ({ ...body, datetime: decodeTimestamp(body.datetime) }));

function Index() {

    const isDesktop = useMediaQuery(theme => theme.breakpoints.up('md'), {noSsr: true});
    const [modalState, setModalState] = useState(false);
    const {auth} = useContext(AuthContext);
    const [appointments, setAppointments] = useState([]);

    const intl = useIntl();

    useEffect(async () => {
        try{
            const { data } = await GET_SERVICE_ORDERS(intl.locale);
            setAppointments(
              appointmentsSerializer(data).reverse()
            )
        } catch(e){ console.log(e) }
    }, []);

    const context = {
        user: {
            ...auth.user,
            avatar: auth.user.avatar
        },
        appointments,
        setModalState
    }

    return (
        <Layout>
            <Container>
                <MainWrap>
                    <MainWrap.Header>
                        <Typography component={"h1"} variant={"h2"}>
                            {intl.formatMessage({ id: "profile" })}
                        </Typography>
                    </MainWrap.Header>
                    <MainWrap.Body>
                        <AccountContext.Provider value={context}>
                            {
                                isDesktop ? <Desktop/> : <Mobile/>
                            }
                        </AccountContext.Provider>
                    </MainWrap.Body>
                </MainWrap>
            </Container>
            <EventModal state={modalState} setState={setModalState}/>
        </Layout>
    )
}

export default observer(Index);

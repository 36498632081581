import React, { useContext, useEffect, useState } from "react"
import AccountContext from "../context.js"
import { makeComponentStyles } from "@components/mixins.js"
import Image from "@components/Account/Image.jsx"
import { Typography, Grid, Paper, Box } from "@material-ui/core"
import AccountInfoLeft from "@static/images/AccountInfoLeft.svg"
import AccountInfoRight from "@static/images/AccountInfoRight.svg"
import ButtonLink from "@components/Buttons/ButtonLink.jsx"
import { navigate } from "gatsby-plugin-react-intl"
import ArrowRight from "@static/images/ArrowRight.svg"
import clsx from "clsx"
import BaseButton from "@components/Buttons/Button.jsx";
import "date-fns";
import DateFnsUtils from "@date-io/date-fns";
import { MuiPickersUtilsProvider, Calendar } from "@material-ui/pickers"
import {useIntl} from "gatsby-plugin-react-intl";
import { Pagination } from "@material-ui/lab"
import Avatar from "@material-ui/core/Avatar"

const AddStyles = ({ palette }) => ({
  root: {
    padding: "25px",
    borderRadius: ".5rem",
    background: palette.primary.light,
    boxShadow: "0px 4px 22px -5px rgba(0, 114, 187, 0.4);"
  },
  button__container: {
    cursor: "pointer",
    margin: "15px 0",
    transition: "transform .25s ease",
    "&:first-of-type": {
      marginTop: 0
    },
    "&:hover": {
      transform: "translateY(-2px)"
    }
  },
  button: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    fontSize: "18px !important"
  },
  appointment_card: {
    marginTop: 20,
    "&:first-of-type": {
      marginTop: 0
    }
  }
})

function Container({ children, className, ...rest }) {
  const { root } = makeComponentStyles(AddStyles)
  className = clsx(root, className)
  return (
    <div className={className} {...rest}>
      {children}
    </div>
  )
}

function Button({ children, to }) {
  const { button, button__container } = makeComponentStyles(AddStyles)
  const handleRedirect = () => navigate(to)
  return (
    <Container onClick={handleRedirect} className={button__container}>
      <ButtonLink className={button}>
        {children} <img src={ArrowRight} alt="arrow-right" />
      </ButtonLink>
    </Container>
  )
}

function AppointmentCard({ body }) {
  const { service: { title, audience }, datetime, specialist: { name } } = body
  const { appointment_card } = makeComponentStyles(AddStyles)
  return (
    <div className={appointment_card}>
      <Typography variant={"h5"} style={{ marginBottom: "10px" }}>{title}</Typography>
      <Typography>{name}</Typography>
      <Typography>{datetime}, {audience}</Typography>
    </div>
  )
}

const ComponentStyles = ({ palette }) => ({
  account_info: {
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    position: "relative"
  },
  user_info: {
    // position: "absolute",
    marginTop: -110,
    display: "flex",
    flexDirection: "column",
    alignItems: "center"
  },
  user_info__avatar: {
    fontSize: 45,
    marginBottom: "15px"
  },
  button_modal: {
    display: "block",
    margin: "20px auto"
  },
  appointments_container: {
    marginBottom: 25,
    width: "100%",
    display: "flex",
    justifyContent: "space-between"
  },
  day_container: {
    border: "solid 1px " + palette.primary.main,
    borderRadius: "50%"
  }
})

function Desktop() {
  const { user, appointments = [], setModalState } = useContext(AccountContext)

  const {
    account_info,
    user_info,
    user_info__avatar,
    button_modal,
    appointments_container,
    day_container
  } = makeComponentStyles(ComponentStyles)

  const intl = useIntl();

  const getAppointmentsByPageNumber = (number) =>
    appointments.slice((number - 1) * pageSize, number * pageSize)

  const pageSize = 3;
  const [page, setPage] = useState(1)
  const [currentAppointments, setCurrentAppointments] = useState([]);

  useEffect(() => {
    if(appointments.length){
      setCurrentAppointments(
        getAppointmentsByPageNumber(1)
      )
    }
  }, [appointments])

  const handlePagination = (event, value) => {
    setPage(value);
    setCurrentAppointments(
      getAppointmentsByPageNumber(value)
    )
  }

  const [date, setDate] = useState(new Date())

  return (
    <>
      <Container style={{ margin: "40px 0" }}>
        <div className={account_info}>
          <img src={AccountInfoLeft} alt="badge-left" />
          <div className={user_info}>
            <Image component={
              ({ className }) => <Avatar alt={user.name} src={user.avatar ?? ' '} className={className} />
            } className={user_info__avatar} />
            <Typography style={{ fontSize: "20px" }}>{user.name} {user.surname}</Typography>
          </div>
          <img src={AccountInfoRight} alt="badge-right" />
        </div>
      </Container>
      <Grid container>
        <Grid item md={4} style={{ paddingRight: "40px" }}>
          <MuiPickersUtilsProvider  utils={DateFnsUtils}>
            <Paper style={{ overflow: "hidden", borderRadius: ".5rem" }} elevation={5}>
              <Calendar
                date={date}
                onChange={(date) => setDate(date)}/>
            </Paper>
          </MuiPickersUtilsProvider>
          <BaseButton className={button_modal} onClick={() => setModalState(true)} type={"primary"}>
            {intl.formatMessage({ id: "suggest_event" })}
          </BaseButton>
        </Grid>
        <Grid item md={8}>
          <Button to={"/users/account/settings"}>
            {intl.formatMessage({ id: "settings" })}
          </Button>
          <Button to={"/users/account/appointment"}>
            {intl.formatMessage({ id: "make_appointment" })}
          </Button>
          <div style={{ marginTop: "35px" }}>
            <Box className={appointments_container}>
              <Typography component={"h3"} variant={"h4"}>
                {intl.formatMessage({ id: "your_appointments" })}
              </Typography>
              <Pagination
                count={Math.ceil(appointments.length / pageSize)}
                page={page} onChange={handlePagination}
                color={"primary"}
              />
            </Box>
            <Container>
              {
                currentAppointments.length
                  ? currentAppointments.map((item, key) => (<AppointmentCard key={key} body={item} />))
                  : ( <Typography variant={"h5"}>{intl.formatMessage({ id: "empty_appointments" })}</Typography> )
              }
            </Container>
          </div>
        </Grid>
      </Grid>
    </>
  )
}

export default Desktop
